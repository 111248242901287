import { FC } from 'react';
import { APP_NAME } from '../constants';
import { Button, Group, Text } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { PlainTextLink } from './PlainTextLink';
import { useUser } from 'hooks/useUser';

interface HeaderProps {
  showNavLinks?: boolean;
}

export const Header: FC<HeaderProps> = ({ showNavLinks = true }) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const logoutUser = async (): Promise<void> => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (e) {
      console.error(e);
    }
  };

  const loggedIn = user?.uid && !user?.isAnonymous;

  return (
    <Group justify='space-between' component='header'>
      <PlainTextLink to='/'>
        <Text>{APP_NAME}</Text>
      </PlainTextLink>

      {showNavLinks && (
        <Group gap='md' component='nav' p='sm'>
          <PlainTextLink to='/'>
            <Text>Home</Text>
          </PlainTextLink>
          {loggedIn ? (
            <>
              <PlainTextLink to='/dashboard'>
                <Text>Dashboard</Text>
              </PlainTextLink>
              <Text onClick={logoutUser} style={{ cursor: 'pointer' }}>
                Logout
              </Text>
            </>
          ) : (
            <>
              <PlainTextLink to='/login'>
                <Text>Login</Text>
              </PlainTextLink>
              <Link to='/register'>
                <Button color='yellow'>Join</Button>
              </Link>
            </>
          )}
        </Group>
      )}
    </Group>
  );
};

export default Header;
