import { getFirestore } from '@firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from '@firebase/functions';
import { EMULATOR_PORT, FUNCTION_REGION } from './constants';
import { initializeApp } from 'firebase/app';
import { browserLocalPersistence, getAuth, setPersistence } from 'firebase/auth';

export const app = initializeApp({
  apiKey: 'AIzaSyAJGaR6UV7hb3G4xHa6ghfnu8a3oFbmA6I',
  authDomain: 'sumvid-72148.firebaseapp.com',
  projectId: 'sumvid-72148',
  storageBucket: 'sumvid-72148.firebasestorage.app',
  messagingSenderId: '34891270933',
  appId: '1:34891270933:web:8f295c20594567efd2b71c',
  measurementId: 'G-QLSVESC0DP',
});

export const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence) // Or browserSessionPersistence
  .then(() => {
    // Existing user?
    return auth.currentUser ? Promise.resolve(auth.currentUser) : Promise.reject();
  })
  .catch(() => {
    // No existing user.
  });

export const db = getFirestore(app);
export const functions = getFunctions(app, FUNCTION_REGION);

if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
  connectFunctionsEmulator(functions, 'localhost', EMULATOR_PORT);
}
