import { Container, Text, Title, Button, Paper } from '@mantine/core';
import Header from '../components/Header';
import { PlainTextLink } from '../components/PlainTextLink';
import { APP_NAME } from '../constants';

export const HomePage = () => {
  return (
    <main>
      <Header />
      <Container>
        <Paper
          shadow='md'
          p='xl'
          mt='xl'
          radius='md'
          style={{ textAlign: 'center', backgroundColor: '#f8f9fa' }}
        >
          <Title order={1}>
            Summarize Videos from YouTube and other Platforms Easily and for Free
          </Title>
        </Paper>

        <Paper ta='center' shadow='md' p='xl' mt='xl' radius='md' bg='gray.0'>
          <Title order={2}>Save Hours of Video Watching</Title>
          <PlainTextLink to='/register'>
            <Button color='yellow' mt='lg'>
              Sign-up for Free
            </Button>
          </PlainTextLink>
        </Paper>

        <Paper shadow='md' p='md' mt='xl' radius='md' style={{ textAlign: 'center' }}>
          <Text>
            &copy; {new Date().getFullYear()} {APP_NAME}. All rights reserved.
          </Text>
          <Text>Developed by Jake Cyr</Text>
        </Paper>
      </Container>
    </main>
  );
};

export default HomePage;
