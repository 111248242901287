import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/home';
import { LoginPage } from './pages/login';
import { MantineProvider } from '@mantine/core';
import { DashboardPage } from './pages/dashboard';
import { RegisterPage } from './pages/register';
import '@mantine/core/styles.css';

export const App: FC = () => {
  return (
    <MantineProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path='/dashboard' element={<DashboardPage />} />
          <Route index element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  );
};
