import { Alert, Button, Container, Text, TextInput, Title } from '@mantine/core';
import { useState } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

export const RegisterPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const register = async () => {
    setError('');

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      setError('Error creating account. Please try again.');
    }
  };

  return (
    <main>
      <Header />
      <Container>
        <Title mt={30}>Register</Title>
        <Text mt={10}>
          Sign-up to receive daily nuggets of information straight to your inbox around 9am based on
          your interests.
        </Text>

        {error && (
          <Alert title='Error creating account' color='red' mt={20}>
            {error}
          </Alert>
        )}

        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await register();
          }}
        >
          <TextInput
            type='email'
            id='email'
            value={email}
            placeholder='Email'
            onChange={(e) => setEmail(e.target.value)}
            mt={20}
            minLength={5}
            required
          />
          <TextInput
            type='password'
            id='password'
            value={password}
            placeholder='Password'
            onChange={(e) => setPassword(e.target.value)}
            mt={20}
            minLength={8}
            required
          />
          <Button color='yellow' type='submit' mt={20}>
            Create Account
          </Button>
        </form>
      </Container>
    </main>
  );
};
