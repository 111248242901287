import { FC, useState } from 'react';
import { Container, Title, Text, Stack, Alert, TextInput, Button } from '@mantine/core';
import Header from '../components/Header';
import { useUser } from '../hooks/useUser';
import { functions } from '../firebase';
import { httpsCallable } from '@firebase/functions';

interface RequestData {
  videoID: string;
}

interface ResponseData {
  summary?: string;
  error?: string;
}

export const DashboardPage: FC = () => {
  const { user, loadingUser } = useUser();
  const [videoID, setVideoID] = useState('');
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const summarizeVideo = async (videoID: string) => {
    try {
      setLoading(true);
      const callable = httpsCallable<RequestData, ResponseData>(functions, 'summarizeVideo');
      const data = await callable({ videoID });
      setSummary(data?.data?.summary || '');
      setError(data?.data?.error || '');
    } catch (e) {
      console.error(e);
      setError(e + '');
    } finally {
      setLoading(false);
    }
  };

  if (loadingUser) {
    return (
      <Container>
        <Text>Loading...</Text>
      </Container>
    );
  }

  if (!user || user.isAnonymous) {
    return (
      <Container>
        <Text>Not logged in.</Text>
      </Container>
    );
  }

  return (
    <main>
      <Header />
      <Container mt={10} mb={50} p='md'>
        <Stack>
          <Title>Summarize Video</Title>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await summarizeVideo(videoID);
            }}
          >
            <TextInput
              label='Video ID'
              value={videoID}
              onChange={(e) => setVideoID(e.target.value)}
              required
              minLength={5}
              disabled={loading}
            />
            <Button loading={loading} type='submit' mt={10}>
              Summarize
            </Button>
          </form>

          {error && (
            <Alert title='Error' color='red' mt={20}>
              {error}
            </Alert>
          )}

          {summary && <Stack mt={20} dangerouslySetInnerHTML={{ __html: summary }}></Stack>}
        </Stack>
      </Container>
    </main>
  );
};
