import {
  Alert,
  Button,
  Container,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { FC, useState } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import * as firebase from "@firebase/auth";

export const LoginPage: FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const login = async () => {
    setError("");

    try {
      await setPersistence(auth, firebase.browserLocalPersistence);
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      console.error(error);
      setError(error + "");
    }
  };

  return (
    <main>
      <Header />
      <Container mt={20}>
        <Title>Login</Title>
        <Text>
          Login to view your previous nuggets and adjust your interests.
        </Text>

        {error && (
          <Alert title="Error Logging In" color="red" mt={20}>
            {error}
          </Alert>
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            login();
          }}
        >
          <TextInput
            type="email"
            id="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            mt={20}
            minLength={5}
            required
          />
          <TextInput
            type="password"
            id="password"
            value={password}
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            mt={20}
            minLength={5}
            required
          />
          <Button color="yellow" type="submit" mt={20}>
            Login
          </Button>
        </form>
      </Container>
    </main>
  );
};
